@font-face {
    font-family: 'WarnerBrosSansCond';
    src: url('../src/assets/fonts/WarnerBrosSansCond-Bold.woff2') format('woff2'),
        url('../src/assets/fonts/WarnerBrosSansCond-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-300';
    src: url('../src/assets/fonts/Inter-Light.woff2') format('woff2'),
        url('../src/assets/fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-400';
    src: url('../src/assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('../src/assets/fonts/Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-500';
    src: url('../src/assets/fonts/Inter-Medium.woff2') format('woff2'),
        url('../src/assets/fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-600';
    src: url('../src/assets/fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../src/assets/fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-700';
    src: url('../src/assets/fonts/Inter-Bold.woff2') format('woff2'),
        url('../src/assets/fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}