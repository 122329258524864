/*Spacing*/
$spacing-spacing-xs:4px; 
$spacing-spacing-sm:16px; 
$spacing-spacing-md:24px;
$spacing-spacing-lg:40px; 
$spacing-spacing-xl:56px; 
$spacing-spacing-none:0px;
/*Radius*/
$radius-minimal:2px;
$radius-rounded:4px;
$radius-radius-full:360px;
$text-primary:#000000;   
$text-invert:#FFFFFF;
$text-link:#015CFE;
$text-muted:#616161;
$text-status:#616161;
$text-CTA-basic:#015CFE;
$text-CTA-basic-hover:#015CFE;
$text-CTA-basic-pressed:#0022AF;
$text-CTA-basic-disabled:#8A8A8A;
$text-CTA-primary:#FFFFFF;
$text-CTA-primary-hover:#FFFFFF;
$text-CTA-primary-pressed:#FFFFFF;
$text-CTA-primary-disabled:#FFFFFF;
$text-CTA-secondary:#FFFFFF;
$text-CTA-secondary-hover:#FFFFFF;
$text-CTA-secondary-pressed:#FFFFFF;
$text-CTA-secondary-disabled:#FFFFFF;
$text-CTA-tertiary:#015CFE;
$text-CTA-tertiary-hover:#015CFE;
$text-CTA-tertiary-pressed:#015CFE;
$text-CTA-tertiary-disabled:#8A8A8A;
/*Surface*/
$surface-primary:#FFFFFF;
$surface-invert:#000000;
$surface-primary-hover:#F5F5F5;
$surface-secondary:#F5F5F5;
$surface-tertiary:#FAFAFA;
$surface-nav-ops:#F5F5F5;
$surface-header:#000000;
$surface-tooltip:#000000;
$surface-CTA-basic:#FFFFFF;
$surface-CTA-basic-hover:#FFFFFF;
$surface-CTA-basic-pressed:#FFFFFF;
$surface-CTA-basic-disabled:#FFFFFF;
$surface-CTA-primary:#000000;
$surface-CTA-primary-hover:#BDBDBD;
$surface-CTA-primary-pressed:#030056;
$surface-CTA-primary-disabled:#EDEDED;
$surface-CTA-secondary:#015CFE;
$surface-CTA-secondary-hover:#0022AF;
$surface-CTA-secondary-pressed:#030056;
$surface-CTA-secondary-disabled:#EDEDED;
$surface-CTA-tertiary:#FFFFFF;
$surface-CTA-tertiary-hover:#E6EFFF;
$surface-CTA-tertiary-pressed:#CCDEFF;
$surface-CTA-tertiary-disabled:#FFFFFF;
$surface-CTA-icon-hover:#EDEDED;
$surface-CTA-icon-focused:#EDEDED;
$surface-CTA-icon-pressed:#BDBDBD;
$surface-forms-controls:#616161;
$surface-forms-selected:#015CFE;
$surface-forms-disabled:#F5F5F5;
$surface-status-closed:#424242;
$surface-status-active:#08A14E;
$surface-status-not :#9000E8;
$surface-status-new:#04006C;
$surface-status-success:#F0FCF5;
$surface-status-warning:#FEFAF4;
$surface-status-blocker:#FFFBFB;
$surface-status-in-progress:#FFFBEF;
$surface-status-pending:#EDEDED;
$surface-snackbar-success:#EDEDED;
$surface-snackbar-error:#FDE9EC;
$surface-calendar-selected:#015CFE;
$surface-calendar-range:#CCDEFF;
/*Border-Color*/
$border-primary:#8A8A8A;
$border-secondary:#E0E0E0;
$border-forms:#8A8A8A;
$border-forms-focused:#015CFE;
$border-forms-error:#BE1A34;
$border-snackbar-success:#06793B;
$border-snackbar-error:#BE1A34;
/*Icons-Color*/
$icon-default:#616161;
$icon-revert:#FFFFFF;
$icon-link:#015CFE;
$icon-success:#08A14E;
$icon-warning:#FD5912;
$icon-blocker:#BE1A34;
$icon-in-progress:#CC9B00;
$icon-pending:#9E9E9E;
$icon-pressed:#0022AF;
$icon-disabled:#E0E0E0;
$icon-form-disabled:#BDBDBD;
/*Box Shadow*/
$shadow-sm:0px 1px 3px 0px rgba(0,0,0,0.25);
$shadow-md:0px 0px 8px 0px rgba(1,92,254,0.60);
$shadow-lg:0px 8px 16px 0px rgba(29,33,45,0.20);