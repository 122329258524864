//ag-grid structure and  custome style
@import "./assets/styles/ag-grid-theme.css";

.common-grid-section {
  z-index: 0;
}

.common-grid-block {
  z-index: 0;
  position: relative;
}

.ag-theme-custom {
  border-radius: var(--spacing-spacing-xs, 4px);
  background: var(--surface-surface-primary, #fff);
  box-shadow: 0px 8px 16px 0px rgba(29, 33, 45, 0.2);

  .ag-root-wrapper {
    .ag-paging-panel {
      display: none;
    }

    .ag-root {
      .ag-header {
        height: 56px !important;
        border-bottom: 0 none;
        
        .ag-header-viewport {
          .ag-header-container {
            background: var(--color-grays-00, #fbfbfb);
            text-transform: uppercase;
            z-index: 0;

            .ag-header-row {
              height: 56px !important;
              align-self: center;
              margin-top: 16px;

              .ag-header-cell-resize {
                &:after {
                  display: none;
                }
              }

              .ag-header-cell-comp-wrapper {
                .ag-cell-label-container {
                  .ag-header-cell-menu-button {
                    opacity: 1;
                  }

                  .ag-header-cell-label {
                    gap: 0px !important;
                    span {
                      .hello {
                        margin-left: 1rem;
                        color: red;
                      }
                    }

                    .ag-sort-indicator-container {
                      .ag-sort-indicator-icon {
                     padding: 0px !important;
                        .ag-icon-asc,
                        .ag-icon-desc {
                          padding:  0;
                        }
                      }
                    }
                  }

                  :hover {
                    color: var(--text-text-primary, #000);
                  }
                }
              }

              .name {
                margin-left: -22px;
              }
              .dept-column{
                margin-left: 30px;
              }
              .dept-col-view{
                margin-left: 7px;
              }
              .org{
                margin-left: -13px;
              }

              .custom-header-adjusting {
                margin-left: -25px;
              }
              .custom-header-margin{
                margin-left: 4rem;
              }
             .ml-8{
              margin-left: -8px;
             }
            }
          }
        }
      }

      .ag-center-cols-viewport {
        .ag-center-cols-container {
          .ag-cell-value {
            span {
              .common-split-cell-section {
                overflow: hidden;
                color: var(--text-text-primary, #000);
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                flex-direction: column;

                .common-split-cell-data-1 {
                  overflow: hidden;
                  color: var(--text-text-primary, #000);
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-family: 'Inter-700';
                  font-size: 14px;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: -0.14px;
                }

                .common-split-cell-data-2 {
                  padding-top: 6px;
                  padding-bottom: 2px;
                  font-family: 'Inter-400';
                  font-size: 14px;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: -0.14px;
                  text-overflow: ellipsis;

                  &.view-user-email {
                    content: "---";
                    margin-bottom: 15px;
                  }
                }

                .common-split-cell-data-3 {
                  width: 100px;
                  text-align: center;
                  border-radius: var(--radius-radius-rounded, 4px);
                  background: var(--surface-surface-forms-controls, #616161);
                  color: var(--color-special-White, #fff);
                  font-family: 'Inter-400';
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 110.9%;
                  letter-spacing: 0.15px;
                  margin-bottom: 24px;
                }

                .pb-common {
                  padding-top: unset;
                  margin-bottom: 3rem;
                }

                .common-split-cell-data-4 {
                  font-family: 'Inter-400';
                  font-style: normal;
                  font-weight: 400;
                  line-height: 110.9%;
                  letter-spacing: 0.15px;
                  margin-top: 16px;
                  margin-bottom: 16px;
                }

                .common-split-cell-mobile-data {
                  font-family: 'Inter-400';
                  font-style: normal;
                  font-weight: 400;
                  line-height: 110.9%;
                  letter-spacing: 0.15px;
                }

                .common-studio-user-request-label {
                  width: 100px;
                  text-align: center;
                  border-radius: var(--radius-radius-rounded, 4px);
                  color: var(--color-special-White, #fff);
                  font-family: 'Inter-400';
                  font-size: 10px;
                  font-style: normal;
                  line-height: 110.9%;
                  letter-spacing: 0.15px;
                  margin-bottom: 24px;
                  padding: var(--radius-sm, 2px) 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;

                  &.system-admin {
                    border-radius: var(--radius-radius-rounded, 4px);
                    background: var(--surface-surface-status-not-started,
                        #04006c);
                    width: 74px;

                    @media (max-width: 510px) {
                      margin-bottom: 5px;
                      padding: 5px;
                      position: relative;
                      top: 5px;
                    }
                  }

                  &.account-payable {
                    border-bottom: 1px solid var(--border-border-secondary, #e0e0e0);
                    background: var(--surface-surface-primary-hover, #9000e8);
                    width: 102px;

                    @media (max-width: 510px) {
                      margin-bottom: 14px;
                    }
                  }

                  &.company-admin {
                    border-bottom: 1px solid var(--border-border-secondary, #e0e0e0);
                    background: var(--surface-surface-primary, #616161);
                    width: 94px;

                    @media (max-width: 510px) {
                      margin-bottom: 14px;
                    }
                  }

                  &.no-data {
                    display: none;
                  }
                }

                .merged-mb {
                  margin-bottom: 6px;
                }

                .bugs-label {
                  margin-bottom: unset;
                }

                .common-split-cell-data-default-font {
                  font-family: 'Inter-400';

                  .passes-table-arrival-time-hide {
                    @media (max-width: 768px) {
                      display: none;
                    }
                  }
                }
              }

              .third-merged-field {
                display: flex;
                padding: var(--spacing-spacing-sm, 16px);
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 0px;
                align-self: stretch;

                .first-row {
                  display: flex;
                  align-items: center;
                  gap: var(--spacing-spacing-sm, 16px);
                  justify-content: space-between;
                  align-self: stretch;

                  .entry-type-row {
                    display: flex;
                    align-items: center;

                    .entry-icon {
                      width: 20px;
                      height: 20px;
                      margin-right: 6px;
                    }

                    .entry-type-text {
                      font-size: 14px;
                    }
                  }

                  .time-text {
                    font-size: 14px;
                  }
                }

                .second-row {
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  align-self: stretch;

                  .name-text,
                  .organization-text {
                    font-size: 14px;
                  }
                }

                .third-row {
                  display: flex;
                  align-items: flex-start;
                  gap: 16px;
                  align-self: stretch;
                }
              }

              .status-time {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
              }

              .view-users-mb {
                margin-bottom: 10px;
              }

              .common-mobile-section {
                margin-bottom: 2rem;
              }

              .company-user-mb {
                margin-bottom: 1rem;
              }

              .common-mobile-section-users {
                margin-bottom: 2.5rem;
              }

              .mob-section-bugs {
                margin-bottom: 2rem;
              }

              .common-split-cell-with-image {
                overflow: hidden;
                color: var(--text-text-primary, #000);
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                padding: 16px 24px;

                .image-section-on-grid {
                  display: inline-block;

                  .image-block {
                    vertical-align: sub;
                    display: inline-block;
                    line-height: normal;
                    margin-right: 10px;
                  }

                  .man-icon-img {
                    width: 24px;
                    height: 24px;
                  }
                }

                .split-column-data-on-grid {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  gap: 2rem;

                  .move-content-on-top {
                    margin-top: -40px;
                  }
                }
              }

              .request-cell {
                display: flex;
                gap: 4px;
                align-items: center;
              }

              .comments-cell {
                display: flex;
                align-items: center;
                gap: 4px;
                flex: 1 0 0;

                .comments-count {
                  overflow: hidden;
                  color: var(--text-text-primary, #000);
                  text-align: center;
                  text-overflow: ellipsis;
                  font-family: 'Inter-400';
                  font-size: 14px;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: -0.14px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }

                .comments-icon {
                  width: 24px;
                  height: 24px;
                  flex-shrink: 0;
                }
              }
            }
          }

          .common-row-padding {
            padding: 24px 10px 24px 0px;
          }
          .common-font-size{
            font-size:smaller;
          }

          .common-row-padding-new {
            padding: 24px 10px 24px 10px;
          }

          .common-cell-data-bold {
            font-family: 'Inter-700';
            text-overflow: ellipsis;
          }

          //overview request
          .cell-right-align {
            text-align: right;
            min-width: 6rem;
          }

          .credit-department-table-section {
            display: flex;
            flex-direction: row;
            line-height: normal;
            justify-content: space-between;
          }

          .credit-project-table-section {
            display: flex;
            flex-direction: row;
            line-height: normal;
            justify-content: space-between;
            padding-top: 8px;
          }

          .cred-dept-coloum {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;
          }

          .ellipsis-email-pass-expected {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 106px;
            display: inline-block;
            margin-top: -13px;
          }

          .credit-department {
            word-break: break-word;
            line-height: 17px;
            white-space: normal;
            padding-bottom: 24px;
          }

          .inter-font {
            color: var(--text-text-primary, #000);
            font-family: 'Inter-400';
            font-size: 14px;
            font-style: normal;
          }

          .credit-review-time {
            white-space: normal;
            word-break: break-word;
            max-width: 95px;
            line-height: 17px;
            text-align: center;
          }

          .credit-app-sm-screen {
            white-space: normal !important;
          }

          .m-16 {
            margin-bottom: 16px;
          }

          .date-request {
            line-height: 5rem;
            margin-top: -21px;
          }

          .credit-date {
            margin-top: -2rem;
          }

          .account-user-common-status-cell {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: smaller;
            margin-left: -9px;

            @media (min-width: 320px) and (max-width: 768px) {
              font-size: 10px !important;
            }
          }
          .checked-status{
            margin-top: 5px;
          }

          .wid-needs-app {
            width: 135px !important;
          }

          .w-auto {
            width: auto;
          }
          .send-to-custom{
            margin-left: -10px;
            font-size: 10px  !important;
          }
          .start-app {
            margin-top: -26px;
            width: 129px;
            margin-right: -2rem;
          }
          .start-app-updated{
            margin-top: -10px;
            width: 110px;
            text-transform: capitalize;
          }
          .finish-app {
            margin-top: -26px;
            width: 144px;
            margin-right: -3rem;
          }

          .mt-pass-expexted {
            margin-top: 1.65rem;
          }

          .mt-common-ag-grid {
            margin-left: 0px;
            margin-top: 1rem;
          }

          .credit-status {
            margin-top: 1rem;
          }

          .new-app-width {
            width: 128px;

            @media (min-width:1330px) and (max-width:2560px) {
              width: auto;
            }
          }
          .new-rev-sub-width{
            width: 168px !important;
          }
          .new-app-margin-left{
            margin-left: 2rem !important;
          }
          .credit-application-status{
            margin-right: -4rem;
          }

          .approved-wid {
            width: auto;
          }

          .need-revision-width {
            width: 132px;

            @media (min-width:1330px) and (max-width:2560px) {
              width: 170px;
            }
          }
          .status-approved-risk{
            width: 167px;
          }
          .ellipsis-email {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }

          .mobile-screen-margin {
            @media (max-width: 532px) {
              margin-bottom: 16px;
            }
          }

          .ellipses-email {
            @media (max-width: 991px) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 150px;
              display: inline-block;
            }
          }

          .mt-common-ag-grid-contant {
            margin-top: 0px;
          }

          .mt-common-ag-grid-new {
            width: 5rem;
            margin-left: 10px;

            @media (min-width: 431px) and (max-width: 480px) {
              margin-left: 1px;
            }

            @media (min-width: 481px) and (max-width: 510px) {
              margin-left: 0px;
            }
          }

          .status-vertically-align {
            vertical-align: middle;
          }

          .account-user-active-project-cell {
            font-family: 'Inter-400';
          }

          .status-container {
            width: 90px;
            margin-top: -15px;

            .common-merged-cell-sm {
              margin-top: 25px;
              margin-left: 0;
              padding: 10px;
            }
          }
          .m-left-36{
            margin-left:36px;
          }
          .m-left-50{
            margin-left:58px;
          }

          .view-five-passes {
            margin-left: 9px;
            margin-top: 4px;
          }

          .checked-in-width {
            width: 112px;
          }

          .checked-out-width {
            width: 130px;
          }

          //only for pass widget
          .passes-status-block {
            float: right;
            margin-top: 10px;
            .arrival-time{
              margin-top: 6px;
            }
          }

          /*Request*/
          .request-comment-cell-end {
            text-align: end;
          }

          .move-right {
            float: right;
            width: auto;
            margin-top: 3px;
          }

          .common-cell-data-word-break {
            font-family: 'Inter-700';
            white-space: normal;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: unset;
          }

          //User-Project
          .user-project-acces-cell {
            text-align: center;
          }

          //Project-user  Account user
          .icon-hover {
            opacity: 0;
            transition: opacity 0.3s ease;
            padding: 7px 0px 7px 3px;
            border-radius: 35px;

            &:hover {
              cursor: pointer;
            }
          }

          .ag-row:hover .icon-hover {
            opacity: 1;
          }

          @media (max-width: 992px) {
            .icon-hover {
              opacity: 1;
              transition: none;
            }

            .ag-cell:hover .icon-hover {
              opacity: 1;
            }

            .icon-inside-cell {
              cursor: pointer;
            }

            .urser-project-cell-mt {
              margin-top: -12px;
            }
          }
        }
      }
    }
  }

  &.no-header {
    .ag-root {
      .ag-header {
        display: none;
      }
    }
  }
}