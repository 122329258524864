@import "../src/fonts.scss";
@import "../src/variable.scss";
@import "./ag-grid-custom-theme.scss";
:root {
  --font-size-small: 24px;
  --font-size-tablet: 32px;
  --font-size-desktop: 36px;
}
// Base font size for small devices
.common-btn-danger {
  &.btn {
    display: flex;
    padding: var(--spacing-spacing-sm, 16px);
    align-items: flex-start;
    gap: 10px;
    color: var(--text-text-invert, #fff);
    text-align: center;
    font-family: "Inter-700",sans-serif;
    font-style: normal;
    line-height: 42px;
    letter-spacing: 0.36px;
    text-transform: capitalize;
    background-color: #be1a34;
    margin: 0 auto;
    font-size: var(--font-size-small);
    @media (min-width: 768px) {
      font-size: var(--font-size-tablet) !important;
    }
    @media (min-width: 992px) {
      font-size: var(--font-size-desktop);
    }
  }
}

// Font size for tablets
.common-btn-primary {
  &.btn {
    border: 0;
    display: inline-flex;
    height: 36px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    font-family: "Inter-600",sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.75px;
    border-radius: var(--radius-radius-rounded, 4px);
    background: var(--surface-surface-CTA-primary, #000);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    &:focus {
      border-radius: 4px;
      background: #000;
      box-shadow: 0px 0px 8px 0px rgba(1, 92, 254, 0.6);
    }
    &:disabled {
      border-radius: 4px;
      background: #ededed;
      color: #8a8a8a;
    }
    &:hover {
      border-radius: 4px;
      background: #0022af;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      color: white;
    }
    &:active {
      border-radius: 4px;
      background-color: #030056 !important;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      color: white !important;
    }
  }
}
.common-btn-secondary {
  &.btn {
    display: inline-flex;
    height: 36px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    color: var(--text-text-CTA-secondary, #fff);
    text-align: center;
    font-family: "Inter-600",sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.75px;
    border: 0;
    border-radius: var(--radius-radius-rounded, 4px);
    background: var(--surface-surface-CTA-secondary, #015cfe);
    &:focus {
      border-radius: var(--radius-radius-rounded, 4px);
      background: var(--surface-surface-CTA-secondary, #015cfe);
      box-shadow: 0px 0px 8px 0px rgba(1, 92, 254, 0.6);
    }
    &:hover {
      border-radius: var(--radius-radius-rounded, 4px);
      background: var(--surface-surface-CTA-secondary-hover, #0022af);
      color: white;
    }
    &:active {
      border-radius: var(--radius-radius-rounded, 4px);
      background: var(--surface-surface-CTA-secondary-pressed, #030056);
    }
    &:disabled {
      border-radius: var(--radius-radius-rounded, 4px);
      background: var(--surface-surface-CTA-secondary-disabled, #ededed) !important;
      color: var(--text-text-CTA-secondary-disabled, #8A8A8A);
    }
  }
}

.common-btn-light {
  &.btn {
    display: inline-flex;
    height: 36px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    color: var(--text-text-CTA-basic, #015cfe);
    text-align: center;
    font-family: "Inter-600",sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.75px;
    border-radius: var(--radius-radius-rounded, 4px);
    border: var(--spacing-spacing-none, 1px) solid
      var(--border-border-secondary, #e0e0e0);
    background: var(--surface-surface-primary, #fff);
    &:focus {
      border-radius: var(--radius-radius-rounded, 4px);
      border: 1px solid var(--border-border-secondary, #e0e0e0);
      background: var(--surface-surface-CTA-tertiary, #fff);
      box-shadow: 0px 0px 8px 0px rgba(1, 92, 254, 0.6);
      color: var(--text-text-CTA-basic, #015cfe);
    }
    &:hover {
      border-radius: var(--radius-radius-rounded, 4px);
      border: 1px solid var(--border-border-secondary, #e0e0e0);
      background: var(--surface-surface-CTA-tertiary-hover, #e6efff);
      color: var(--text-text-CTA-basic, #015cfe);
    }
    &:active {
      border-radius: var(--radius-radius-rounded, 4px);
      border: 1px solid var(--border-border-secondary, #e0e0e0);
      background: var(--surface-surface-CTA-tertiary-pressed, #ccdeff);
      color: var(--text-text-CTA-basic, #015cfe) !important;
    }
    &:disabled {
      border-radius: var(--radius-radius-rounded, 4px);
      border: var(--spacing-spacing-none, 1px) solid
        var(--border-border-secondary, #e0e0e0);
      background: var(--surface-surface-CTA-tertiary-disabled, #fff);
    }
  }
}
.common-btn-sent {
&.btn{
height: 28px;
padding: 4px 8px 4px 8px !important;
justify-content: center;
align-items: center;
font-family: "Inter-400",sans-serif;
font-size: 12px;
border-radius: var(--radius-radius-full, 360px);
background: var(--surface-surface-status-new, #04006C);
color:#ffff;
&:hover {
  border-radius: 360px;
  background: #04006C;
  color: white;
}
  }
}
//common icons
.icon-wrapper {
  display: flex;
  width: 36px;
  height: 36px;
  padding: var(--spacing-spacing-none, 9px);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--radius-radius-full, 360px);
  background: var(--Surface-Transparent, rgba(255, 255, 255, 0));
}
//page title
.page-title {
  color: var(--text-text-primary, #000);
  font-family: "Inter-700",sans-serif;
  font-size: 36px;
  font-style: normal;
  line-height: 42px;
  letter-spacing: 0.36px;
  text-transform: capitalize;
  &.no-border-bottom {
    border-bottom: none !important;
  }
  @media (min-width: 1200px) {
    font-size: 32px;
  }
  
}
.para-text {
  color: #000;
  font-family: "Inter-400",sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}

//common inputs
/*Handelling small error section in form*/
.error-handling{
  position: relative;
  bottom: 2rem;
}
.custom-floating-label {
  position: relative;
  margin-bottom: 1rem;
  input.form-control {
    &.error {
      border: 1px solid var(--border-border-forms, #be1a34);
      &:focus {
        border: 1px solid var(--border-border-forms, #be1a34);
      }
    }
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--border-border-forms, #8a8a8a);
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 2px;
    overflow: hidden;
    color: var(--text-text-primary, #000);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter-400",sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    &:focus {
      box-shadow: none;
      border-radius: var(--radius-sm, 2px);
      border: 1px solid var(--border-border-forms-focused, #015cfe);
    }
    &::placeholder {
      overflow: hidden;
      color: #616161;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "Inter-400",sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      @media (max-width: 420px) {
        font-size: 10px!important;
      }
    }
    &.w-80{
      @media (max-width:510px) {
        width: 85%!important;
        
      }
    }
  }
  input[disabled] {
    background-color: #F5F5F5;
    cursor: not-allowed;
    color: #6c757d;
  }
  .search-icon {
   
    position: absolute;
    top: 6px;
    right: 6px;
    background: transparent;
    padding: 12px;
    @media (max-width: 420px) {
      right: 1px;
    }
  }
  label.form-label {
    &.error {
      color: #be1a34;
    }
    position: absolute;
    transform: translateY(-50%);
    transition: all 0.2s;
    pointer-events: none;
    background-color: white;
    padding: 0 0.25rem;
    top: 0px;
    left: 20px;
    font-size: 12px;
    color: #495057;
    box-shadow: none;
  }
}

//common text link
.text-link {
  color: #015cfe;
  font-family: "Inter-500",sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #015cfe;
  }
}
//Comman User Status
.user-status-tag {
  display: inline-flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size:12px;
  padding: 2px 8px 2px 8px;
  flex-shrink: 0;
  border-radius: var(--radius-radius-full, 360px);
  border: 1px solid var(--border-border-secondary, #e0e0e0);
  &.active {
    background: var(--surface-surface-status-success, #f0fcf5);
    width: 5rem;
    &::before {
      background-image: url("../src/assets/images/resolved.svg");
    }
  }
  &.deactivated {
    background: var(--surface-surface-status-pending, #ededed);
    width:7rem;
  }
  &::before {
    content: "";
    display: inline-block;
    min-width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 0;
    background-image: url("../src/assets/images/deactivated.svg");
  }
}
.common-mb-status-tag{
  content: ' ';
  margin-bottom: 20px;
}
//Comman Request Status ans passes status
.request-status-tag {
  text-transform: uppercase;
  border-radius: var(--radius-radius-full, 360px);
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Inter-600",sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.14px;
  height: 28px;
  padding: 5px 8px;
  justify-content: center;
  &.new {
    color: var(--text-text-invert, #fff);
    background: var(--surface-surface-status-new, #04006c);
    width: 80px;
    display: flex;
    justify-content: center;
  }
  &.closed {
    color: var(--text-text-invert, #fff);
    background: var(--surface-surface-status-closed, #424242);
    width: 80px;
  }
  &.assigned-color,
  &.resolved-color,
  &.inprogress-color,
  &.checked-in,
  &.checked-out {
    border: 1px solid var(--border-border-secondary, #e0e0e0);
    color: var(--text-text-muted, #616161);
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      background-size: cover;
    }
  }
  &.assigned-color {
    background: var(--color-grays-00, #fbfbfb);
    &::before {
      background-image: url("/assets/images/assigned.svg");
    }
  }
  &.resolved-color {
    background: var(--surface-surface-status-success, #f0fcf5);
    &::before {
      background-image: url("/assets/images/resolved.svg");
    }
  }
  &.checked-in{
    background: var(--surface-surface-status-success, #f0fcf5);
    &::before {
      background-image: url("/assets/images/approve.svg");
    }
  }
  &.checked-out {
    min-width: 6.8rem;
    background: var(--surface-surface-status-success, #EDEDED);
    &::before {
      background-image: url("assets/images/checked-out.svg");
    }
  }
  &.inprogress-color {
    background: var(--surface-surface-status-in-progress, #fffbef);
    width: 110px;
    &::before {
      background-image: url("/assets/images/in-progress.svg");
    }
  }
  @media (max-width: 1330px) and (min-width: 511px) {
    font-size: 9.5px;
  }
  @media (max-width: 510px) and (min-width: 320px) {
    font-size: 10px;
  }
}
//Comman passes Status
.passes-status-tag {
  text-transform: uppercase;
  border-radius: var(--radius-radius-full, 360px);
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Inter-600",sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.14px;
  height: 28px;
  padding: 5px 8px;
  justify-content: center;
  

  &.checked-in {
    background: var(--surface-surface-status-success, #f0fcf5);
    &::before {
      background-image: url("/assets/images/resolved.svg");
    }
  }
  @media (max-width: 1330px) and (min-width: 511px) {
    font-size: 9.5px;
  }
  @media (max-width: 510px) and (min-width: 320px) {
    font-size: 10px;
  }
}
//Comman Project Status
.project-status-tag {
  border-radius: var(--radius-radius-full, 360px);
  height: 29px;
  margin: auto 0px auto auto;
  padding: 4px 16px;
  gap: 4px;
  z-index: 10;
  text-transform: uppercase;
  color: var(--text-text-invert, #fff);
  font-size: 12px;
  font-family: "Inter-600",sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 0;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    height: 22px;
    font-size: 10px;
  }
  @media (min-width: 1200px) and (max-width: 1300px) {
    height: 23px;
    font-size: 10px;
  }
  &.active-tag-green {
    background: var(--surface-surface-status-active, #08a14e);
  }
  &.closed-tag-black {
    background: var(--surface-surface-status-closed, #424242);
  }
  &.not-started-purple {
  
    background: var(--surface-surface-status-not-started, #9000e8);
    @media (min-width: 1200px) and (max-width: 2560px) {
      padding:9px;
      height:26px;
    }
  }
}
//Comman AR Status
.ar-status-tag {
  display: inline-flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  color: var(--text-text-status, #616161);
  font-family: "Inter-600",sans-serif !important;
  font-size: 12px !important;
  font-style: normal;
  line-height: normal;
  padding: 9px 16px;
    width: 150px;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  border-radius: var(--radius-radius-full, 360px);
  border: var(--spacing-spacing-none, 1px) solid
    var(--border-border-secondary, #e0e0e0);

  &.new-application {
    border: none;
    background: var(--surface-surface-status-new, #04006c);
    color: var(--text-text-invert, #fff);
    width: 128px !important;
    padding: 5px 8px !important;
    &::before {
      content: none;
    }
  }
  &.new {
    color: var(--text-text-invert, #fff);
    background: var(--surface-surface-status-new, #04006c);
    width: 80px;
    display: flex;
    justify-content: center;
    &::before{
      content: none !important;
    }
  }
  &.new-request {
    border: none;
    background: var(--surface-surface-status-new, #04006c);
    color: var(--text-text-invert, #fff);
    width: 112px !important;
    padding: 5px 8px !important;
    &::before {
      content: none;
    }
  }
  &.closed {
    color: var(--text-text-invert, #fff);
    background: var(--surface-surface-status-closed, #424242);
    width: 72px;
    padding: 6px 12px;
  }
  &.cancelled {
    color: var(--text-text-invert, #fff);
    background: var(--surface-surface-status-closed, #424242);
    width: 97px;
    padding: 6px 12px;
  }
  &.revision-submitted {
    border: none;
    background: var(--surface-surface-status-new, #04006c);
    color: var(--text-text-invert, #fff);
    width: 168px !important;
    padding: 5px 8px !important;
    &::before {
      content: none;
    }
  }
  &.application-submitted{
    width:164px !important; 
  }
  &.start-application{
    background: var(--color-neutrals-black, #000);
     box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: var(--radius-radius-rounded, 4px);
    color: #FFFFFF;
    text-transform: capitalize;
    width: 150px !important;
    &::before{
      content: "+";
      width: 18px;
      height: 18px;
      position: relative;
      top: -2px;
      font-size: 1rem;
      left: 3px;
    }
  }
  &.finish-application {
    background: var(--color-neutrals-black, #000);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: var(--radius-radius-rounded, 4px);
    text-transform: capitalize;
    color: #FFFFFF;
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 5px 8px;
    width: 150px !important;
  
    &::before {
      content: "";
      width: 16px;
      height: 18px;
      display: inline-block;
      background-image: url('assets/images/like.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      flex-shrink: 0;
    }
  }
  
  
  

  &.under-review,
  &.in-progress,
  &.revision,
  &.needs-approval,
  &.needs-renewal,
  &.approved,
  &.submitted,
  &.need-approve,
  &.send-to,
  &.denied-cross,
  &.cod,
  &.denied {
    position: relative;
  }

  &.send-to{
    background: var(--surface-surface-status-new, #04006C);
    color: #FFF;
    width: max-content;
    &::before {
      background: url("../src/assets/images/send.svg");
    }
  }

  &.under-review {
    background: var(--surface-surface-status-in-progress, #fffbef);
    padding: 2px 0px 2px 4px;
    width: 130px;
    &::before {
      background: url("../src/assets/images/in-progress.svg");
      margin-left: -5px;
    }
  }
  &.in-progress {
    background: var(--surface-surface-status-in-progress, #fffbef);
    padding: 2px 0px 2px 4px;
    width: 120px;
    &::before {
      background: url("../src/assets/images/in-progress.svg");
      margin-left: -5px;
    }
  }
  &.assigned {
    background: var(--surface-surface-status-in-progress, #EDEDED);
    padding: 2px 0px 2px 4px;
    width: 102px;
    &::before {
      background: url("../src/assets/images/assigned.svg");
      margin-left: -5px;
    }
  }

  &.revision {
    background: var(--surface-surface-status-warning, #fefaf4);
    padding: 2px 0px 2px 12px;
    &::before {
      background: url("../src/assets/images/warning.svg");
    }
  }

  &.needs-approval {
    background: var(--surface-surface-status-pending, #ededed) !important;
    width: 155px !important;
    &::before {
      background: url("../src/assets/images/grey-check-icon.svg") !important;
      margin-left: -12px;
    }
  }
  &.need-approve {
    background: var(--color-grays-00, #FBFBFB);
    padding: 2px 0px 2px 4px;
    width: 163px;
    &::before {
      background: url("assets/images/pending-icon.svg");
      margin-left: -12px;
    }
  }

  &.approved {
    width: fit-content;
    background: var(--surface-surface-status-success, #f0fcf5);
    &::before {
      background: url("../src/assets/images/approve.svg");
    }
  }
  &.resolved {
    padding: 9px 9px 9px 16px !important; 
    width: fit-content;
    background: var(--surface-surface-status-success, #f0fcf5);
    &::before {
      background: url("../src/assets/images/resolved.svg");
    }
  }
  &.submitted {
    padding: 9px 9px 9px 16px !important; 
    width: fit-content;
    background: var(--surface-surface-status-success, #f0fcf5);
    &::before {
      background: url("../src/assets/images/resolved.svg");
    }
  }
  &.cod {
    background: var(--surface-surface-status-info, #E6E5F0);
    margin-top: 3px;
    width: 165px !important;
    &::before {
      background: url("assets/images/COD.svg");
      margin-left: -8px;
      margin-top: 3px;
    }
  }
  &.denied {
    background: var(--surface-surface-status-blocker, #fffbfb);
    width: 91px;
    &::before {
      background: url("../src/assets/images/deny-cross.svg");
    }
  }
  &.approved-risk {
    background: var(--surface-surface-status-success, #f0fcf5);
    width: 154px;
    padding: 2px 0px 2px 4px;
    &::before {
      background: url("../src/assets/images/approve.svg");
    }
  }
  &.denied-cross {
    background: var(--surface-surface-status-blocker, #fffbfb);
    &::before {
      background: url("../src/assets/images/deny-cross.svg");
    }
  }
  &::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 24px;
    background-repeat: no-repeat !important;
    background-size: contain;
    margin-left: -12px;
  }
  @media (max-width: 1330px) and (min-width: 511px) {
    font-size: 9.5px;
  }
  @media (max-width: 510px) and (min-width: 320px) {
    font-size: 9px;
  }
}
/*Circular Pass Status */
.progress_container {
  display: flex;
  align-items: center;
  padding: var(--spacing-spacing-xl, 56px) 0px;
  gap: var(--spacing-spacing-xs, 4px);
  .pass-status-widgets {
    display: flex;
    position: relative;
    width: 84px;
    height: 84px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--radius-radius-full, 360px);

    @media (max-width: 376px) {
      width: 63px;
      height: 63px;
    }
    .pass-status-widgets-values {
      top: 29px;
      position: absolute;
      color: var(--text-text-invert, #fff);
      font-family: 'Inter-600',sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      @media (max-width: 376px) {
        top: 19px;
      }
    }
    .pass-status-widgets-labels {
      display: flex;
      justify-content: center;
      gap: 4px;
      bottom: -31px;
      position: absolute;
      overflow: hidden;
      color: var(--text-text-primary, #000);
      text-overflow: ellipsis;
      font-family: 'Inter-400',sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.14px;
      width: max-content;
    }

    &.widget-darkblue {
      background: var(--surface-surface-status-new, #04006c);
      border: 1px solid #04006c;
    }

    &.widget-blue {
      background: #0f4eae;
      border: 1px solid #0f4eae;
    }
    &.widget-green {
      background: var(--icon-icon-in-progress, #08a14e);
      border: 1px solid #08a14e;
    }
    &.widget-grey {
      background: var(--icon-icon-warning, #e0e0e0);
      border: 1px solid #e0e0e0;
    }
    &.widget-darkgrey {
      background: var(--icon-icon-default, #616161);
      border: 1px solid #616161;
    }
  }
  .progress_container_arrow {
    align-self: center;
  }
}
//Comman-Title & Filter block for all pages
.comman-title-block {
  padding: 0;
  .comman-title-section {
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 10px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .button-group {
      .btn-right {
        margin-right: 16px;
        @media (min-width: 320px) and (max-width: 375px) {
          margin-bottom: 16px;
        }
      }
    }
    .top-nav-company{
      .back-link{
        font-size:16px!important;
        line-height: 14px;
       }
    }
    h1 {
      font-family: 'Inter-700',sans-serif;
      font-size: 36px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.15px;
      margin: 0;
      @media (min-width: 310px) and (max-width: 410px) {
        font-size: 18px;
      }
      @media (min-width: 411px) and (max-width: 509px) {
        font-size: 24px;
      }
      @media (min-width: 510px) and (max-width: 768px) {
        font-size: 32px;
      }
    }
  }
  .comman-filter-block {
    background: var(--surface-surface-primary, #fff);
    display: flex;
    padding: var(--spacing-spacing-sm, 16px);
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    gap: var(--spacing-spacing-md, 24px);
    .comman-filter-title-section {
      display: flex;
      width: 100%;
      margin-bottom: 24px;
      justify-content: space-between;
      .sort-text{
        @media (min-width: 320px) and (max-width: 768px){
          position: relative;
          top: 13px;
          }
      }
      p {
        overflow: hidden;
        color: #8e8e8e;
        text-overflow: ellipsis;
        text-transform: uppercase;
        font-family: "Inter-700",sans-serif;
        font-size: 13px;
        padding: 0;
        font-style: normal;
        line-height: 16px;
        letter-spacing: 0.13px;
        margin-bottom: 0;
        &:nth-child(2) {
          transform: translateX(-150px);
        }
      }
    }
    .comman-filter-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px !important;
      width: 100%;

    }
    .filter-sort-block {
      @media (min-width: 992px) {
        justify-content: flex-end;
      }
      span {
        text-align: center;
        font-family: "Inter-600",sans-serif;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 0.75px;
        border: 0;
      }
    }
  }
}

//scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
// checkbox
/* Basic reset and styles */
/* Custom checkbox container */
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
}
/* Hide default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Custom checkmark */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fcfcfc;
  border: 2px solid #b0b0b0;
  border-radius: 0; /* Rounded corners */
}
/* Show checkmark when checkbox is checked */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #015cfe;
  border: 2px solid #015cfe;
}

/* Checkmark icon (white tick) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark icon */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark icon */
.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* Disable styles for disabled checkbox */
.custom-checkbox input:disabled ~ .checkmark {
  opacity: 1;
  background-color: #fcfcfc;
  border-color: #e2e2e2;
}

.custom-checkbox input:checked:disabled ~ .checkmark {
  background-color: #a3c4ff;
  border: 2px solid #dedede;
}
.custom-checkbox input:disabled ~ .checkmark:after {
  border-color: #fff;
}
.custom-checkbox.disabled {
  color: #aaa;
  cursor: not-allowed;
}
/*Tabs styling*/
.comman-tabs-block {
  padding: 0px 0px 24px 0px;
  ul.nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li.nav-item {
      margin-right: 24px;
      .nav-link {
        font-family: "Inter-600",sans-serif;
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
        letter-spacing: 0.75px;
        padding: 8px 0px;
        text-decoration: none;
        color: var(--color-grayscale-Gray-02, #616161);
        transition: background-color 0.3s ease, transform 0.3s ease;
        position: relative;
        &:hover,
        &.active {
          color: var(
            --color-semantic-TO-DELETE-info-TO-DELETE-Info-Blue-100,
            #015cfe
          );
          border-bottom: 4px solid var(--text-text-link, #015cfe);
          padding: 8px 0px;
        }
      }
    }
  }
}
//common modal
.common-modal-xl {
  margin-top: 80px;
  border-radius: 16px 16px 0px 0px !important;
  .modal-body {
      padding-top: 30px;
      padding-bottom: 30px;
      @media (max-width:510px) {
        padding-top: 45px;
        padding-bottom: 45px;
      }
      .cm-header-section{
          position: relative;
          .cm-subtitle{
                  color: var(--text-text-muted, #616161);
                  text-align: center;
                  font-family: 'Inter-400',sans-serif;
                  font-size: 20px;
                  font-style: normal;
                  line-height: normal;
          }
          .modal-title{
              color: #000;
              text-align: center !important;
              width: 100%;
              font-family: 'Inter-700',sans-serif;
              font-size: 36px;
              font-style: normal;
              line-height: 42px;
              letter-spacing: 0.36px;
              text-transform: capitalize;
              margin-bottom: 20px;
              @media (max-width:510px) {
                font-size: 24px;
                margin-bottom: 0px;
              }
          }
          .btn-close {
              position: absolute;
              top: 10px;
              right: 0px;
              color: var(--text-text-muted, #616161);
              font-family: 'Inter-400',sans-serif;
              font-size: 16px;
              font-style: normal;
              line-height: normal;
              .cm-close-text{
                  position: absolute;
                  right: 35px;
                  top:2px;
              }
              @media (max-width:510px) {
                top: -25px;
                right: -10px;
              }
          }
          .back-link{
            position: absolute;
              top: 10px;
              font-size: 16px;
              left: 0px;
              cursor: pointer;
              @media (max-width:510px) {
                top: -25px;
                right: -10px;
              }
          }
          }
      .cm-main-container{
          max-width: 840px;
          &.cm-main-container-lg{
              max-width: 1140px;
          }
          .cm-btn-section{
         padding:0 0px 30px 0px;
         margin-bottom:30px;
            text-align: right;
            @media (max-width: 510px) {
              text-align: center;
            }
          }
          .common-modal-input-section{
                  padding: 46px;
                  margin-bottom: 46px;
                  border-radius: var(--spacing-spacing-xs, 4px);
                  background: var(--surface-surface-primary, #FFF);
                  box-shadow: 0px 8px 16px 0px rgba(29, 33, 45, 0.20);
                  @media (max-width:510px) {
                    box-shadow: none;
                    padding: 0;
                    border-bottom: 1px solid var(--border-border-secondary, #E0E0E0);
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                  }
                 
              .custom-floating-label {
                  margin-bottom: 25px;
             
              }
              .common-text-area{
                border-radius: 2px;
                border: 1px solid var(--border-border-forms, #8A8A8A);
                color: var(--color-special-Black, #000);
                  font-family: 'Inter-400',sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  line-height: normal;
                  @media(max-width:510px){
                    font-size:12px;
                  }
              }
             
              .section-main-header{
                  color: var(--text-text-primary, #000);
                  text-align: center;
                  font-family: 'Inter-700',sans-serif;
                  font-size: 24px;
                  font-style: normal;
                  line-height: 28px;
                  margin-bottom: 25px;
                  letter-spacing: 0.24px;
                  @media (max-width:510px) {
                    font-size: 20px;
                  }
              }
              
              .form-section-header{
                
                  margin-bottom: 12px;
                  color:#616161;
                  font-family: 'Inter-700',sans-serif;
                  font-size: 13px;
                  font-style: normal;
                  line-height: 16px;
                  letter-spacing: 0.13px;
                  text-transform: uppercase;
              }
              .custom-checkbox{
                  margin-bottom: 20px;
              }
              .main-acc-wrapper{
                  padding: 16px;
                  border: var(--spacing-spacing-none, 1px) solid var(--border-border-secondary, #E0E0E0);
                  .acc-sec-header{
                      color: var(--text-text-primary, #000);
                      font-family: 'Inter-700',sans-serif;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 24px; 
                  }
                  .accordion{
                      .accordion-button{
                          padding: 10px 0 15px 0px;
                          &:focus{
                              outline: none !important;
                              box-shadow: none;
                          }
                          &:not(.collapsed){
                              background-color: transparent;
                              box-shadow: none;
                          }
                          &:after{
                              margin-left: 15px;
                          }
                          .eye{
                              margin-right: 15px;
                          }
                      }
                  }
                  .switch{
                      height: 24px;
                      width: 45px;
                  }
                  .text-link{
                    img{
                      max-width:16px;
                      max-height:16px;
                      margin-right:4px;
                      margin-top:-2px;
                    }
                  }
              }
              .text-color-red{
                color: var(--text-text-error, #BE1A34);
                font-family: 'Inter-700',sans-serif;
                font-size: 13px;
                font-style: normal;
                line-height: 16px; 
                letter-spacing: 0.13px;
                text-transform: uppercase;
                }

                  //Cognito View
  .cog-page-wrapper-modal {
    display:  unset ;
    .header-cognito {
        display: flex;
        flex-direction: column;
      .wbd-logo {
        max-width: 25%;
        height: 43px;
        align-self: center;
      }
      .header-content{
        padding-top: 25px;
        align-items: center;
        display: flex;
        flex-direction: column;
        h1{
          font-family: "Roboto Slab", Helvetica, sans-serif;
          font-size: 31.98px;
          font-weight: 700;
        }
        p{
          font-family: "Roboto Slab", Helvetica, sans-serif;
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
    .terms-conditions-cognito{
      display: block;
      font-family: "Roboto Slab", Helvetica, sans-serif;
      font-size: 13px;
      font-weight: 400;
      margin-top: 25px;
      line-height: 18px;
    }
    .input-text-data{
      margin-top: 25px;
      p{
        font-family: "Roboto Slab", Helvetica, sans-serif;
        font-size: 13px;
        font-weight: 400;
      }
      .letter-foramt{
        font-family: "Roboto Slab", Helvetica, sans-serif;
        font-size: 13px;
        font-weight: 400;
      }
      .costume-heading{
        display: flex;
        flex-direction: column;
        align-items: center;
        h3{
          font-family: "Roboto Slab", Helvetica, sans-serif;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .heading-content{
      h2{
        font-family: "Roboto Slab", Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 700;
      }
    }

          }
          }
          .mb-60{
            margin-bottom:60px!important;
          }
      }
  }
}

.common-modal-adjust{
  margin-top: 0px !important;
}

//comman-location-button
.comman-location-btn {
  border-radius: var(--radius-radius-full, 360px);
  margin-left: 10px;
  .rounded-button {
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--radius-radius-full, 360px);
    background: var(--surface-surface-invert, #000);
    border: 1px solid #000;
    i {
      margin-top: -10px;
      width: 12px;
      height: 14px;
    }
    span {
      color: var(--text-text-invert, #fff);
      font-family: "Inter-600",sans-serif;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.12px;
    }
  }
}

//pagination
.common-pagination-section {
  .common-pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 50px;
    font-size: 16px;
    @media (max-width:450px) {
      display: block;
      text-align: center;
    }
    .items-per-page {
      display: flex;
      align-items: center;
      gap: var(--spacing-1, 8px);
      @media (max-width: 510px) {
        display: block;
      }
      .per-page {
        color: var(--Grays-Light-Gray-02, var(--Grays-Light-Gray-02, #616161));
        font-family: "Inter-400",sans-serif;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      select {
        padding: 8px 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        width: 80px;
        height: 40px;
      }
      .size-per-page {
        width: 100px;
        height: 50px;
        flex-shrink: 0;
        stroke-width: 1px;
        stroke: var(--Grays-Light-Gray-04, #9e9e9e);
      }
    }
    .common-pagination-navigation-block {
      .pagination-no {
        color: var(--Grays-Light-Gray-02, #616161);
        display: flex;
        align-items: center;
        font-family: "Inter-400",sans-serif;
        font-size: 12px;
        font-style: normal;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.4px;
        margin-right: 1rem;
        padding-left: 6px;
        @media (max-width: 510px) {
          margin-right: 0;
        }
        @media (max-width:450px) {
          display: -webkit-inline-box;
        }
        .paginator-arrow-icons {
          display: flex;
          align-items: center;
          margin-left: 1rem;

          .arrow-1,
          .arrow-2 {
            display: flex;
            width: 18px;
            height: 18px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            cursor: pointer;
          }

          .arrow-2 {
            margin-left: 2rem;
          }
        }
      }
    }
    .common-pagination-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      @media (max-width:450px) {
        display: block;
        text-align: center;
        margin-bottom: 1.5rem;
      }
      .per-page{
        color: var(--Grays-Light-Gray-02, var(--Grays-Light-Gray-02, #616161));
        font-family: 'Inter-400',sans-serif;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        letter-spacing: 0.4px;
        @media (max-width:450px) {
          margin-right: 8px;
        }
      }
    }
  }
}
//multiselct
.multiselect-dropdown {
  width: 100%;
  height: 36px !important;
  .dropdown-btn {
    font-size: 12px;
    border-radius: 0 !important;
    padding: 0;
    border: 1px solid #9e9e9e;
    margin: 0;
    display: block;
    width: 119px !important;
    height: 36px !important;

    .dropdown-multiselect__caret {
      background-color: #f5f5f5;
      height: 33px !important;
      top: 2px !important;

      &:before {
        color: black;
        font-size: 12px;
      }
    }

    .selected-item-container {
      display: table-cell !important;
      float: none !important;
      position: relative;
      top: 62px;
      right: 28px;

      .selected-item {
        background: var(--surface-surface-primary, #fff) !important;
        border: none !important;
        max-width: 300px !important;
        padding: 7px 12px !important;

        span {
          border-radius: var(--spacing-spacing-xs, 4px) !important;
          background: var(--surface-surface-primary, #fff) !important;
          color: var(--Special-Black, var(--Special-Black, #000));
          font-family: "Inter-400",sans-serif;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.14px;
        }

        a {
          display: inline-block;
          border-radius: var(--spacing-spacing-xs, 20px) !important;
          border: 1px solid #000 !important;
          padding: 0px 5px 0px 3.6px !important;
          background-color: #616161;
          width: 14px;
          height: 15px;
          font-weight: 700;
          font-size: 8.4px;
        }
      }
    }
  }

  .dropdown-list {
    width: 221px !important;

    .item1 {
      .multiselect-item-checkbox {
        display: none;
      }

      .filter-textbox {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-family: "Bootstrap Icons";
          font-weight: 400;
          font-size: 16px;
          color: #007bff;
          content: "\e163";
        }
      }
    }

    .item2 {
      li {
        div {
          color: var(
            --Grays-Light-Gray-00,
            var(--Grays-Light-Gray-00, #212121)
          ) !important;

          font-family: "Inter-400",sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        ::before {
          border: 2px solid #8a8a8a !important;
        }

        ::after {
          background-color: #015cfe !important;
        }
      }
    }
  }
  .dropdown-menu{
    padding: 5px 10px 15px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0px 1px 5px #959595;
    /*Datepicker*/
      .my-0{
        border-top: 0px solid #ffffff !important;
      }
      .ngb-dp-header{
         background: #ffffff;
          ngb-datepicker-navigation{
            .ngb-dp-navigation-select{
              .form-select{
                margin: 2px;
                &:focus{
                  border-color: #dee2e6;
                  outline: 0;
                  box-shadow: 0 0 0 0 transparent;
              }
              }
            }
            .ngb-dp-arrow{
              .btn-link {
                --bs-btn-color: #616161;
                font-size: 10px;
              }
            }
          }
      }
      .ngb-dp-content{
        .ngb-dp-month{
          ngb-datepicker-month{
            .ngb-dp-weekday,.ngb-dp-week{
              background: #ffffff;
              text-align: center;
              font-family: 'Inter-400',sans-serif;
              font-size: 16px;
              font-style: normal;
              line-height: 28px; 
              letter-spacing: 0.44px;
            }
            .ngb-dp-weekday{
              color: var(--text-text-muted, #616161);
              border-bottom: 0px solid #ffffff !important;
              padding-top: 5px;
            }
            .ngb-dp-week{
              color: var(--text-text-primary, #000);
              border-bottom: 0px solid #ffffff !important;
              .ngb-dp-day{
                .custom-day{
                  text-align: center;
                  padding: 0.185rem 0.25rem;
                  display: inline-block;
                  height: 2rem;
                  width: 2rem;
                  border-radius: 50%;
                  &.focused {
                    background-color: #ffffff;
                    border:1px solid #616161;
                    border-radius: 50%;
                  }
                  &:hover {
                    background-color: #015CFE;
                    color: #ffffff;
                  }
                  &.range{
                    background-color: #015CFE;
                    color: #ffffff;
                  }
                  &.faded{
                    background-color: #CCDEFF;
                    color: #000000;
                  }
                }
              }
            }
          }
          }
        }
      }
      .custom-date-input{
        padding-left: 12px;
        &:focus {
          outline: none;
        }
      }
      .custom-date-button{
        border-radius: 0px;
        height: 36px;
        border-color: #9e9e9e;
        background: #f5f5f5;
        border-left: 0px;
        z-index: 0 !important;
        position: absolute !important;
        left: 79px;
        width: 41px;
        padding-left: 9px;
        &:active {
          background: #f5f5f5;
          border-color: #9e9e9e;
        }
        &:hover {
          background: #f5f5f5;
          border-color: #9e9e9e;
        }
        .datepicker-multiselect__caret:before {
          position: relative;
          right: -2px;
          top: -25%;
          color: #999;
          border-style: solid;
          border-color: #999999 transparent;
          content: "";
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
      ::placeholder {
        color: #000000;
        opacity: 1;
      }
      /*End Datepicker*/
}
.custom-date-div{
  width: 119px !important;
  .dropdown-menu{
    @media (min-width: 510px) and (max-width: 767px) {
       left: -130px !important;
    position:absolute !important;
    }
  }
}
//common alerts
.alert-danger {
  &.common-alert-danger {
    border-radius: var(--spacing-spacing-none, 0px);
    background: var(--surface-surface-snackbar-error, #fde9ec);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    margin-top: 24px;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left: 8px solid #be1a34;
    p {
      color: var(--text-text-primary, #000);
      font-family: 'Inter-400',sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}

/*Radio Button*/
.common-radio-btn {
  display: flex;
  align-items: center;
  gap: 0px;

  .common-radio-input {
    position: relative;
    width: 20px;
    height: 20px;
    appearance: none;
    margin-right: 16px;
    background-color: #fff;
    border: 1.5px solid #8a8a8a;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:checked::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      background-color: #015cfe;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &:disabled {
      background-color: #f0f0f0;
      border-color: #c0c0c0;
      cursor: not-allowed;
    }

    &:disabled:checked {
      border-color: #c0c0c0;
      cursor: not-allowed;
    }

    &:disabled:checked::before {
      background-color: #015cfe;
    }
  }

  .common-radio-label {
    @media (min-width: 320px) and (max-width: 375px) {
      font-size: 13px;
    }
    color: var(--text-text-primary, #000);

font-family: 'Inter-400',sans-serif;
font-size: 16px;
font-style: normal;
line-height: normal;
    color: #333;
    cursor: pointer;
    user-select: none;
    margin-bottom: 0;
    text-transform: capitalize;
    word-break: break-word;
    .common-form-check-input:disabled ~ & {
      color: #a0a0a0;
      cursor: not-allowed;
    }
  }
  .common-font {
    @media (min-width: 320px) and (max-width: 450px) {
      font-size: 9px;
    }
  }
}

.credit-status-tag {
  display: flex;
  height: 28px;
  padding: var(--radius-sm, 2px) 18px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--text-text-invert, #fff);
  font-family: "Inter-600",sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  border-radius: var(--radius-radius-full, 360px);
 
  &.current {
    background: var(--surface-surface-status-active, #08a14e);
  }
 
  &.needs-approval {
    background: var(--surface-surface-status-not-started, #9000E8);
  }
  &.needs-renewal {
    background: var(--surface-surface-status-pending, #fd5912) !important;
    text-transform: capitalize !important;
 }
}
 
   /*Common dropdown  for grid and studio-projects */
  .custom-dropdown {
    position: relative;
    display: inline-block;
   
    &::after {
      content: "";
      background: url("assets/images/keyboard_arrow_down.svg") no-repeat center;
      position: absolute;
      top: 1px !important;
      bottom:1px !important;
      border-left: 1px solid #8A8A8A;
      right: 0;
      width: 32px; /* Increase the width for better visibility */
      margin-right: 1px;
      height: 48px;
      background-color: var(
        --surface-surface-secondary,
        #f5f5f5
      ); /* Updated background color */
      background-size: 16px; /* Increase the arrow size */
      pointer-events: none;
      @media(min-width:1920px){
        border-top:1px solid #8A8A8A;
        border-bottom:1px solid #8A8A8A;
      }
    }
   
    select.custom-select {
      width: 100%;
      height: 50px;
      padding-right: 35px; /* Adjust padding to accommodate larger arrow */
      padding-left: 10px;
      border: 1px solid #8a8a8a;
      background-color: #fff;
      appearance: none;
      font-family: "Inter-400",sans-serif;
      font-size: 13px;
      line-height: 20px;
      color: #000;
      border-radius: 0;
   
      &:focus {
        outline: none;
        border-color: #555;
      }
    }
   
    .dropdown-option {
      font-family: "Inter-400",sans-serif;
      font-size: 13px;
      padding: 10px;
      background: #fff;
      color: #000;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    }
  }

/* Common checkbox*/
.common-checkbox-button {
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;

  .common-checkbox-input {
    position: relative;
    min-width: 20px;
    height: 20px;
    appearance: none;
    background-color: #fcfcfc;
    border: 1.5px solid #8a8a8a;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:checked::before {
      content: "";
      position: absolute;
      top: 45%;
      left: 50%;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:checked {
      background-color: #015cfe;
      border-color: #015cfe;
    }

    &:disabled {
      background-color: #f0f0f0;
      border-color: #c0c0c0;
      cursor: not-allowed;
    }

    &:checked:disabled {
      background-color: #a3c4ff;
      border-color: #dedede;
    }
  }

  .common-checkbox-label {
    font-size: 16px;
      font-family: 'Inter-400';
      text-transform: capitalize;
    color: var(--text-text-primary, #000);
    // cursor: pointer;

    .common-checkbox-input:disabled ~ & {
      color: #a0a0a0;
      cursor: not-allowed;
    }
  }
  .ellipses-email{
    @media(min-width:481px) and (max-width:550px) {
      font-size: 14px;
      white-space: nowrap;
    width: 351px;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    @media(min-width:431px) and (max-width:480px) {
      font-size: 14px;
      white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    @media(min-width:320px) and (max-width:430px) {
      font-size: 14px;
      white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    }
  }
}

/*Custom - Toggle */
.common-toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;

  .common-toggle-input {
    position: relative;
    width: 40px;
    height: 24px;
    appearance: none;
    border-radius: 100px;
    background-color: #616161;
    cursor: pointer;
    transition: background-color 0.3s ease, padding 0.3s ease;

    &:before {
      content: "";
      position: absolute;
      top: 4px;
      left: 0px;
      width: 16px;
      height: 16px;
      background-color: #015cfe;
      border-radius: 50%;
      transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease,
        box-shadow 0.3s ease;
    }

    &:checked {
      background-color: #015cfe;
    }

    &:checked::before {
      transform: translateX(20px);
      border: 4px solid white;
      width: 16px;
      height: 16px;
      box-shadow: none;
    }

    &:not(:checked) {
      padding: 0;
    }

    &:not(:checked)::before {
      width: 16px;
      height: 16px;
      top: 4px;
      left: 4px;
      background-color: #616161;
      border: 4px solid white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      transform: translateX(0); /* Keep the knob at the left */
    }

    &:disabled {
      background-color: #8a8a8a;
      cursor: not-allowed;
      opacity: 0.36;
    }

    &:disabled::before {
      background-color: #8a8a8a;
    }

    &:checked:disabled {
      background-color: #015cfe; /* Specific background color for checked and disabled state */
    }

    &:checked:disabled::before {
      background-color: #015cfe;
      transform: translateX(20px);
      border: 4px solid white;
    }
  }

  .common-toggle-label {
    color: var(--text-text-primary, #000);
    font-family: 'Inter-400',sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.14px;

    .common-toggle-input:disabled ~ & {
      color: #a0a0a0;
      cursor: not-allowed;
    }
  }
}

    
    /*Common centered modal*/
    .common-modal-center {
      display: flex;
      padding: var(--spacing-spacing-lg, 40px);
      flex-direction: column;
      align-items: flex-end;
      gap: var(--spacing-spacing-xl, 56px);
      border-radius: 0;
      font-family: 'Inter-600',sans-serif;
  
      .modal-content {
        border-radius: var(--radius-radius-rounded, 4px);
    
        .modal-body {
          display: flex;
          padding: 20px 16px;
          flex-direction: column;
          gap: var(--spacing-spacing-xl, 20px);
    
          p {
            color: var(--text-text-primary, #000);
            font-family: 'Inter-400',sans-serif;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
    
            &.title-bold {
              font-family: 'Inter-700',sans-serif;
    
            }
          }
    
          label {
            color: var(--text-text-muted, #616161);
            font-size: 13px;
            text-transform: capitalize;
          }
          .section-main-header{
            font-size: 18px;
          }
        }
      }
      @media (max-width:576px) {
        padding: 5px!important;
      }
    }

.status-account-tag {
  display: flex;
  width: 92px;
  height: 28px;
  margin-top: 20px;
  padding: var(--radius-sm, 2px) 8px var(--radius-sm, 2px) 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 9.5px;
  line-height: normal;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  font-family: "Inter-600",sans-serif;
  flex-shrink: 0;
  border-radius: var(--radius-radius-full, 360px);
  border: 1px solid var(--border-border-secondary, #e0e0e0);
  &.disabled {
    background: var(--surface-surface-status-pending, #ededed);
    margin-top: 4px;
    @media (max-width:534px) {
      margin-top: 0px;
    }
    &::before {
      content: "";
      display: inline-block;
      min-width: 20px;
      height: 20px;
      background-repeat: no-repeat !important;
      background-size: contain;
      background-image: url("../src/assets/images/deactivated.svg");
    }
  }
}
/*Common Tooltip*/
.bs-tooltip-bottom{
  .tooltip-inner{
   background: var(--surface-surface-tooltip, #000);
    border-radius: 2px !important;
    color: var(--text-text-invert, #FFF);
    font-family: 'Inter-400',sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }
}

/* Snackbar-aka-toast */
.toast-container {
  color: var(--text-text-primary, #000);
  font-family: "Inter-400",sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.16px;
  .toast {
    .toast-body {
      padding: 0;
      .position-relative{
        text-transform: capitalize;
      }
      .remove-text-transform{
        text-transform: none!important;
      }
      .close-toast {
        position: absolute;
        right: 6px;
        top: 1px;
      }
      .danger {
        padding: 16px 24px;
        height: 80px;
        display: flex;
        align-items: center;
        border-radius: var(--spacing-spacing-none, 0px);
        border-left: 8px solid var(--border-border-snackbar-error, #be1a34);
        background: var(--surface-surface-snackbar-error, #fde9ec);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      }
      .success{
        padding: 16px 24px;
        height: 80px;
        display: flex;
        align-items: center;
        border-radius: var(--spacing-spacing-none, 0px);
        border-left: 8px solid var(--border-border-snackbar-success, #06793b);
        background: var(--surface-surface-snackbar-success, #e6faef);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      }
      .warning{
        padding: 16px 24px;
        height: 80px;
        display: flex;
        align-items: center;
        border-radius: var(--spacing-spacing-none, 0px);
        border-left: 8px solid var(--border-border-snackbar-success, #ffdd50);
        background: var(--surface-surface-snackbar-success, #fff3cd);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
      }
      .success-text-transform{
        text-transform: none!important;
      }
      .total-action-content{
        padding: 16px 24px;
        height: 108px;
        justify-content: center;
        border-radius: var(--spacing-spacing-none, 0px);
        border-left: 8px solid var(--border-border-snackbar-success, #06793b);
        background: var(--surface-surface-snackbar-success, #e6faef);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
        gap: 8px;
        .inner-content{
          color: var(--text-text-primary, #000);
          font-family: 'Inter-400',sans-serif;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
          letter-spacing: -0.16px;
        }
        .action-content{
          display: flex;
          align-items: flex-start;
          gap: var(--spacing-spacing-lg, 40px);

          .action-1, .action-2 {
            display: flex;
            color: #015CFE;
            width: var(--spacing-7, 56px);
            align-items: center;
            gap: var(--spacing-spacing-xs, 4px);
          }
        }
      }

      .total-action-content-danger {
        padding: 16px 24px;
        height: 108px;
        justify-content: center;
        border-radius: var(--spacing-spacing-none, 0px);
        border-left: 8px solid var(--border-border-snackbar-error, #be1a34);
        background: var(--surface-surface-snackbar-error, #fde9ec);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
        gap: 8px;

        .inner-content-danger {
          color: var(--text-text-primary, #000);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.16px;
        }

        .action-content-danger {
          display: flex;
          align-items: flex-start;
          gap: var(--spacing-spacing-lg, 40px);

          .action-3, .action-4 {
            display: flex;
            color: #015CFE;
            width: var(--spacing-7, 56px);
            align-items: center;
            gap: var(--spacing-spacing-xs, 4px);
          }
        }
      }
    }
  }
}


.selected-filters-section {
  .selected-filters {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    flex-wrap: wrap;

    .filter-tag {
      border-radius: var(--spacing-spacing-xs, 4px);
      background: var(--surface-surface-primary, #FFF);
      color: var(--Special-Black, var(--Special-Black, #000));
      font-family: 'Inter-400',sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
      padding: 7px 12px;
      display: flex;
      gap: inherit;

      button {
        display: flex;
        justify-content: center;
        background-color: #616161;
        border: 2px solid #616161;
        border-radius: 50%;
        color: #fff;
        font-size: 10px;
        cursor: pointer;
        outline: none;
        padding: 0px 1px 1px 1px;
        height: 18px;
        width: 18px;
      }
    }

    a {
      font-family: 'Inter-500',sans-serif;
      cursor: pointer;
    }
  }
}

/*Up content blue-- near arrow*/
.text-blue{
  display: flex;
  align-items: center;
  color: var(--color-hero-blue-Blue-100, #015CFE);
  font-family: 'Inter-500',sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  gap: 8px;
}
//Class used for handboob/directory/maps
.view-text {
  @media (max-width:768px) {
      margin-bottom: 20px;
  }
  @media (max-width:378px) {
      margin-bottom: 20px;
  }
  padding: 0px 0 20px 0;
  border-bottom: 1px solid var(--border-border-primary, #8A8A8A);
  margin-bottom: 20px;


.change-weather-title-block{
  font-size: 18px;
    font-family: 'Inter-400',sans-serif;
  
    .change-weather-title {
      font-family: 'Inter-700',sans-serif;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
      margin-right: 24px;
  }
  .change-weather-toggle{
    color: var(--text-text-CTA-basic, #015CFE);
    text-align: center;
    font-family: 'Inter-600',sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 16px; 
    letter-spacing: 0.75px;
    cursor: pointer;
    i{
      margin-left:3px;
      font-size: small;
    }
    @media(max-width:510px){
      display: block;
      text-align: start;
      margin-top:10px;
    }
  }
}
}
.title-content{
  color: #000;
  font-family: 'Inter-400',sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;

  &.review-content{
    @media (max-width:510px) {
      font-size: 16px !important;
      

    }
  }
}


.wrap-text {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.page-wrapper {
	padding: var(--spacing-spacing-xl, 20px) 88px;
	background: var(--surface-surface-secondary, #F5F5F5);

	@media (max-width: 768px) {
		padding: var(--spacing-spacing-xl, 20px) var(--spacing-spacing-sm, 24px);
	}
	@media (max-width:378px) {
		padding: var(--spacing-spacing-xl, 20px) var(--spacing-spacing-sm, 16px);
	}
}

//Style added to fix modal fuulscreen  issue
.modal-fullscreen .modal-content {
  height: auto !important;
  min-height: 100%;
  }
  .common-search-input{
  .dropdown-menu.show {
    max-height: 300px;
    overflow-y: scroll;
    width:100%;

    .active {
        background-color: white !important; 
    }
  }
}

.lh-unset{
  line-height: unset !important;
}
.clickable {
  cursor: pointer;
}
.not-clickable{
  cursor: unset !important;
}

div[contenteditable="true"] {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

[contenteditable="true"] a {
  cursor: pointer;
}

//file upload common css
.file-progress-bar.progress{
  height: 4px;
  div.progress-bar{
      span{
          position: absolute ;
          right: 0px ;
          color: var(--text-text-muted, #616161);
          font-family: 'Inter-700',sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          top: 10px;
      }
  }
}

//common-style-for-modal
.page-wrapper-modal {
  display: flex;
  padding: var(--spacing-spacing-lg, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3, 24px);
  align-self: stretch;
  background: #FFF;
  font-family: "Inter-400",sans-serif;
  @media (max-width: 768px) {
    padding: 25px;
    }
  .page-header {
    h2 {
      flex: 1 0 0;
      color: var(--text-text-primary, #000);
      font-family: "Inter-700",sans-serif;
      font-size: 24px;
      font-style: normal;
      line-height: 28px; /* 116.667% */
      letter-spacing: 0.24px;
      @media (max-width:510px) {
        font-size: 16px;
      }
    }
  }
  .refrences {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-spacing-md, 24px);
    align-self: stretch;

    .bank-reference,
    .trade-reference {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-spacing-sm, 16px);
      align-self: stretch;

      .bank, .trade {
        color: var(--text-text-primary, #000);
        font-family: "Inter-700",sans-serif;
        font-size: 20px;
        font-style: normal;
        line-height: 24px;
      }

      .content {
        display: flex;
        padding: var(--spacing-spacing-none, 0px) var(--spacing-spacing-md, 24px);
        align-items: center;
        gap: var(--spacing-spacing-xs, 4px);
        color: var(--text-text-primary, #000);
        font-family: "Inter-400",sans-serif;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
      }
    }

    .db {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-spacing-sm, 16px);
      align-self: stretch;

      h3 {
        color: var(--text-text-primary, #000);
        font-family: "Inter-700",sans-serif;
        font-size: 20px;
        font-style: normal;
        line-height: 24px;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-spacing-xs, 4px);

        .all-contents {
          display: flex;
          padding: var(--spacing-spacing-none, 0px) var(--spacing-spacing-md, 24px);
          align-items: center;
          gap: var(--spacing-spacing-xs, 4px);

          span {
            color: var(--text-text-primary, #000);
            font-family: "Inter-400",sans-serif;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
          }

          .content-number {
            color: var(--text-text-primary, #000);
            font-family: "Inter-700",sans-serif;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
          }
        }
      }
    }
  }

  .information-content {
    .content {
      color: var(--text-text-primary, #000);
     
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
  }
  .title-company-project {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    .company,
    .project,
    .name {
      color: var(--text-text-muted, #616161);
      display: flex;

      align-items: baseline;
      gap: 8px;
      .company-heading,
      .project-heading,
      .name-heading {
          color: var(--text-text-muted, #616161);
          font-family: 'Inter-700',sans-serif;
          font-size: 13px;
          font-style: normal;
          line-height: 16px; 
          letter-spacing: 0.13px;
          text-transform: uppercase;
        @media (max-width:510px) {
            font-size: 12px;
        }
      }
      .company-content,
      .project-content,
      .name-content {
          color: var(--color-special-Black, #000);
          font-family: 'Inter-400',sans-serif;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.14px;
        @media (max-width:510px) {
            font-size: 12px;
        }
      }
    }
  }
  .section-text-area{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      background: #FFF;
      margin-bottom: 16px;
      margin-top: 16px;
      .section-text{
          color: var(--text-text-primary, #000);
          font-family: 'Inter-700',sans-serif;
          font-size: 20px;
          font-style: normal;
          line-height: 24px;
          margin-bottom: 0px;
      }
      .text-area {
          width: 100%;
          height: 128px;
          flex-shrink: 0;
          padding: 16px;
          border-radius: 2px;
          border: 1px solid var(--border-border-forms, #8a8a8a);
          color: var(--text-text-primary, #000);
          font-family: "Inter-400",sans-serif;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          @media (max-width: 510px) {
            width: 100%;
          }
        }
    }
    .refrence-letter-section{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 4px;
      h3 {
          color: var(--text-text-primary, #000);
          font-family: 'Inter-700',sans-serif;
          font-size: 20px;
          font-style: normal;
          line-height: 24px;
          @media (max-width:510px) {
            font-size: 16px;
          }
        }
    }
}
.file-drop-area {
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  color: #6c757d;
  transition: background-color 0.3s;
  border: 2px dashed #E0E0E0;
  background: #FAFAFA;
  .folder-icon {
      cursor: pointer;
  }
  .drag-n-drop {
      color: var(--text-text-primary, #000);
      text-align: center;
      font-family: 'Inter-700',sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
  }
  .browse-file-link {
      color: var(--text-text-link, #015CFE);
      cursor: pointer;
      font-family: 'Inter-700',sans-serif;
      text-decoration: none;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
  }
}
.end-wrapper {
  display: flex;
  padding: var(--spacing-spacing-lg, 24px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 0px 0px 4px 4px;
  background: var(--surface-surface-secondary, #f5f5f5);
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  padding: 25px;
  }
  .button{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
  }
}

.save-btn-wrapper{
  display: flex;
  width:100%;
  justify-content: flex-end;
  .save-btn{
    margin-left:10px;
  }
}
.section-main-header {
  &.text-left{
      text-align: left !important;
  }
}

.comment-list{
  border-bottom: 1px solid #ccc;
  padding: 20px 0 10px;
  &.no-border{ border: 0;}
}

.user-icon-text{
  display: block;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  border-radius: 50%;
  color: white;
  background-color: #04006C;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  font-weight: 700;
}
.font-14{
  font-size: 14px;
}

.review-panel{ 
  h4{
  font-family: 'inter-700';
  margin-bottom: 0;
  font-size: 20px;
  }
}

.grey-box{
  background-color: #FAFAFA !important;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 50px;
}

.step-list{
  list-style: none;
  padding-left: 0;
  li{
      padding: 2px 0;
  }
  span.step-icon{
      display: inline-block;
      width: 24px;
      height: 26px;
      vertical-align: middle;
      margin-right: 5px;
      background-repeat: no-repeat;
  }
  span.step-uncheck{
    background-image: url(assets/images/status-normal.svg);
  }
  .step-text{
      display: inline-block;    
      padding: 7px 10px;    
      border-radius: 5px;
      width: calc(100% - 29px); 
      color: var(--text-text-primary, #000);
      font-family: 'Inter-400',sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      cursor: pointer;
      @media (max-width:992px) {
        width: auto;
      }
  }
  span.current-step{
      background-color: black; 
      color: #fff;    
  }    
  span.step-check{
      background-image: url(assets/images/status-check.svg);
      position: relative;
      top: 2px;
  }
  span.step-check-grey{
      background-image: url(assets/images/check-grey.svg);
  }
}

.reviewed-status{
  background: url(assets/images/action-reviewed.svg) no-repeat 3px 2px;
  font-size: 12px;
  background-color:#F0FCF5;
  color: #616161;
  border-radius: 13px;
  border: 1px solid #E0E0E0;
  padding: 5px;
  padding-left: 30px;
}
.summary-step h5, .grey-box h5{
  font-size: 20px;
  font-weight: 700;
}
.next-back-btns-section {
  .back-link{
    position: relative;
    top: 6px;
  }
}

//common-dropdown
.btn-common-dropdown{
  .dropdown-toggle {
    &.open::after {
      transform: translateY(-50%) rotate(180deg);    
    }
    border-radius: var(--radius-radius-minimal, 2px);
    border: var(--0, 1px) solid var(--border-border-forms, #8A8A8A);
    background: #FFF;
    color: var(--text-text-muted, #616161);
    text-overflow: ellipsis;
    font-family: 'Inter-400',sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    padding: 10px 40px 10px 15px;
    width: 220px;
    text-align: left;
    height: 39px;
    &:hover, &:active{
      background-color: transparent !important;
      color: #616161 !important;
    }
    &:after {
      content: url('../src/assets/images/keyboard_arrow_down.svg');
      position: absolute;
    
      top: 50%;
      transform: translateY(-50%); 
      pointer-events: none; 
      border: 0;
    
    line-height: 50px;
    height: 96%;
    background: #f5f5f5;
    color: white;
    right: 1px;
    width: 35px;
    text-align: center;
    }
  }
  
  .dropdown-menu{
    width: 100%;
    border-radius: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    .dropdown-item{
      color: var(--text-text-primary, #000);
      font-family: 'Inter-400',sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
      padding: 6px 15px;
    }
  }
  img{
    margin-right: 5px;
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }
}
.span-info{
  font-family: "Inter-400",sans-serif;
  font-size: 12px;
  color:$text-muted;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display:block;
}
@mixin icons{
  width:16px;
  height: 16px;
  display:inline-block;
  background-repeat:no-repeat;
  background-position:center center;
  background-size:100%;
}
.upload-icon{
  background-image:url("../src/assets/images/upload-icon.svg");  
  @include icons;
}
.help-icon{
  background-image:url("assets/images/help-icon.svg");
  @include icons;
}
.calender-icon{
  background-image:url("assets/images/calender-icon.svg");
  @include icons;
}
.arrow-left{
  background-image:url("../src/assets/images/arrow-left.svg");
  @include icons;
}

.custom-container {
  padding-left: 50px !important;
  padding-right: 50px !important;
  max-width: 100% !important;
  width: calc(100% - 10px)!important;
  @media (min-width: 1600){
    padding-top: 0px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    width: calc(100% - 10px) !important;
  }
  @media (max-width: 1366){
    padding-top: 0px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    width: calc(100% - 10px) !important;
  }
  @media (max-width: 1280) {
    padding-top: 0px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    width: calc(100% - 10px) !important;
  }
  .container{
    margin-right: auto !important;
    max-width: initial !important;
    width: initial !important;
  }
}

.uploaded-files-section {
  padding-right: 0px;
  position: relative;
  .row {
    .view-remove {
      display: flex;
      gap: 16px;
      padding: 0px;
      justify-content: flex-end;
      @media (max-width: 576px) {
        display: block;
        text-align: right;
      }
      .fb-600 {
        font-family: "Inter-600",sans-serif;
        text-decoration: none;
        font-size: 14px;
      }
    }
  }
  .bi-x::before {
      font-size: 22px;
      vertical-align: middle;
      line-height: 22px;
  }
  .file-name {
      color: #57636F;
      font-family: 'Inter-400',sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.14px;

      @media (max-width: 510px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 240px;
          display: inline-block;
      }

      @media (max-width: 375px) {
          width: 195px;
          display: inline-block;
      }
  }
  .list-group-item {
      border: none;
  }
  .remove-btn {
      background: transparent;
      border: 0;
      font-size: 20px;
      cursor: pointer;
  }
}
.file-upload-modal-body {
  padding: 30px 30px 20px 30px !important;
  @media (max-width: 420px) {
      padding: 30px 10px 30px 10px;
  }
  .modal-title {
      color: var(--text-text-primary, #000);
      font-family: 'Inter-700',sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.24px;
  }
  .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
  }
}

///common-ankush-modal

.modal {
  .container {
    .modal-left-section {
      .common-left-section-title {
        color: var(--text-text-primary, #000);
        font-family: "Inter-700",sans-serif;
        font-size: 24px;
        font-style: normal;
        line-height: 28px;
        letter-spacing: 0.24px;
        margin-bottom: 24px;
      }
      .company-meta-data {
        .title {
          display: flex;
          padding-bottom: 10px;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          .common-title {
            color: var(--text-text-muted, #616161);
            font-family: "Inter-700",sans-serif;
            font-size: 13px;
            font-style: normal;
            line-height: 16px; /* 123.077% */
            letter-spacing: 0.13px;
            text-transform: uppercase;
          }
          .common-content {
            color: var(--color-special-Black, #000);
            font-family: "Inter-400",sans-serif;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.14px;
          }
        }
        .common-content {
          color: var(--color-special-Black, #000);
          font-family: "Inter-400",sans-serif;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.14px;
        }
      }
    }
    .container {
      .common-modal-input-section {
        font-family: 'Inter-400',sans-serif;
        .row {
          .right-section-heading {
            color: var(--text-text-primary, #000);
            font-family: "Inter-700",sans-serif;
            font-size: 20px;
            font-style: normal;
            line-height: 24px;
          }
        }
        .common-user-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-spacing-xs, 4px);
          .title {
            display: flex;
            align-items: flex-start;
            gap: var(--spacing-spacing-xs, 4px);
            .common-title {
              color: var(--text-text-primary, #000);
              font-family: "Inter-700",sans-serif;
              font-size: 16px;
              font-style: normal;
              line-height: normal;
            }
            .common-content {
              color: var(--text-text-primary, #000);
              font-family: "Inter-400",sans-serif;
              font-size: 16px;
              font-style: normal;
              line-height: normal;
            }
          }
        }
        .file-width{
          max-width: 100%; 
          overflow: hidden; 
          text-overflow: ellipsis;
        }
        .summary-details {
          margin-bottom: 24px;
          align-items: center;
          .summary-heading {
            color: var(--text-text-primary, #000);
            font-family: "Inter-700",sans-serif;
            font-size: 20px;
            font-style: normal;
            line-height: 24px;
          }
        
        }
      }
      .grey-box {
        .common-heading {
          color: var(--text-text-primary, #000);
          font-family: "Inter-700",sans-serif;
          font-size: 24px;
          font-style: normal;
          line-height: 28px;
          letter-spacing: 0.24px;
        }
        .inner-content {
          color: var(--text-text-primary, #000);
          font-family: "Inter-400",sans-serif;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
        }
        .refrence-section {
          .common-reference {
            color: var(--text-text-primary, #000);
            font-family: "Inter-700",sans-serif;
            font-size: 20px;
            font-style: normal;
            line-height: 24px; /* 120% */
          }
          .db-section {
            .left-section {
              color: var(--text-text-primary, #000);
              font-family: "Inter-400",sans-serif;
              font-size: 16px;
              font-style: normal;
              line-height: normal;
            }
            .right-section {
              color: var(--text-text-primary, #000);
              font-family: "Inter-700",sans-serif;
              font-size: 16px;
              font-style: normal;
              line-height: normal;
              margin-left: 4px;
            }
          }
        }
        .comment-section {
          .comment-list {
            .commnet-icon-section {
              .user-icon-text {
                color: var(--text-text-invert, #fff);
                font-family: "Inter-700",sans-serif;
                font-size: 12px;
                font-style: normal;
              }
            }
            .common-comment-section {
              .common-content-bold {
                color: var(--text-text-primary, #000);
                font-family: "Inter-700",sans-serif;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.14px;
              }
              .common-small-content {
                color: var(--text-text-primary, #000);
                font-family: "Inter-400",sans-serif;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.14px;
              }
              .common-normal-content {
                color: var(--text-text-primary, #000);
                font-family: "Inter-400",sans-serif;
                font-size: 16px;
                font-style: normal;
                line-height: normal;
              }
              .common-content-extra-bold {
                color: var(--text-text-primary, #000);
                font-family: "Inter-700",sans-serif;
                font-size: 16px;
                font-style: normal;
                line-height: normal;
              }
            }
          }
        }
        .scroller{
          overflow-y: auto;
          max-height: 600px;
          overflow-x: hidden;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .text-area-block{
        textarea{
          color: var(--text-text-muted, #616161);
            font-family: 'Inter-400',sans-serif;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
            border-radius:0;
            border: 1px solid var(--border-border-forms, #8A8A8A);
              background: var(--surface-surface-primary, #FFF);
            height: 150px;
            padding:16px;
          
        }
      }
    }
  }
}
.p-0{
padding: 0!important;
}
.w-150{
  width: 150px!important;
}
.help-icon{
  width: 18px;
  height: 18px;
}
.grey-background{
  background: var(--surface-surface-secondary, #F5F5F5) !important;
}
.no-project-msg{
  font-family: 'Inter-500',sans-serif;
}
.hash-tricks{
  color: red;
  font-size: 14px;
}
.custom-no-rows{
  font-family: 'Inter-500',sans-serif;
}
/* For Maps Integration */
.page-details-section {
  a{
    color: var(--text-text-link, #015CFE);
    font-family: 'Inter-700',sans-serif;
    font-size: 16px;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
.organized{
    margin-bottom: 0;
    text-align: left;
    word-break: break-word;
    padding-bottom: 12px;
    li{
      color: var(--text-text-primary, #000);
      font-family: 'Inter-400',sans-serif;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      padding-bottom: 16px;
      text-align: left;
      word-spacing: -1px;
  }
}

h2{
    color: var(--text-text-primary, #000);
    font-family: 'Inter-700',sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    margin-bottom: 0;
    padding: 0 0 24px 0;
}
  .elementor-heading-title {
    color: var(--text-text-primary, #000);
    font-family: 'Inter-700',sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: 28px;
    margin-bottom: 0;
    letter-spacing: 0.24px;
    text-transform: capitalize !important;
  }
  
  .elementor-icon-list-items {
    list-style: none !important;   
    padding-left: 0;
    padding-top: 24px;
    li {
        &:last-child{
            padding-bottom: 0;
        }
        padding-bottom: 16px;
    }

    .elementor-icon-list-item {
      position: relative; 
      color: var(--text-text-link, #015CFE);
      font-family: 'Inter-500',sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
      text-decoration: none;
      padding-left: 28px !important;
      cursor: pointer;
      a {
        text-decoration: none;
      }
      &::before {
        content: ""; 
        position: absolute;
        left: 0;
        top: 35%;
        transform: translateY(-50%);
        width: 20px; 
        height: 20px; 
        background-image: url('assets/images/pdf-icon.svg') !important; 
        background-size: contain;
        background-repeat: no-repeat;
      }
      .text-link-maps{
        color: #015cfe;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid #015cfe;
        }
      }
    }
  }
}
.alert {
  background-color: #fffbef !important;
  position: absolute !important;
  width: 100%;
  font-family: "Inter-400",sans-serif;
  font-size: 14px;
  @media (max-width:768px) {
    z-index: 1 !important;
  }

  strong {
    color: black !important;
  }

  span {
    padding: 0;
    margin: 0;
    color: black !important;
  }
}.w-95{
  width: 85% !important;

}
.f-14{
  font-size: 14px!important;
}
.mr-3{
  margin-right:3px;
}

.custom-dropdown-new {
  position: relative;
  height: 42px;
  display: inline-block;
  border-radius: var(--radius-radius-minimal, 2px);
  background: #FFF;
  width: 100%;

  &::after {
    content: "";
    background: url("./assets/images/keyboard_arrow_down.svg") no-repeat center;
    background-color: var(
      --surface-surface-secondary,
      #f5f5f5
    );
    border-radius: 0px var(--radius-radius-minimal, 2px) var(--radius-radius-minimal, 2px) 0px;
    border: 1px solid var(--border-border-forms, #8A8A8A);
    position: absolute;
    top: 21px !important;
    transform: translateY(-50%);
    border-left: 1px solid #8A8A8A;
    right: -1px;
    width: 32px;
    height: 100%;
    pointer-events: none;
  }

  .selected-value {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5px 10px 5px 10px;
    color: #000;
    cursor: pointer;
    font-family: "Inter-400", sans-serif;
    font-size: 11.4px;
    border: 1px solid #8a8a8a;
  }
  
  .options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #8A8A8A;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }

  .dropdown-option {
    font-family: "Inter-400", sans-serif;
    font-size: 11.4px;
    padding: 5px 10px 5px 10px;
    background: #fff;
    color: #000;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ .options {
    display: block;
  }
}
.existing-company{
  display: flex;
  @media(max-width:576px){
  display: block !important;
  }
}